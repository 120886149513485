import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';

import AspectRatio, { AspectRatioWrapper } from 'components/common/AspectRatio';
import FormInput from 'components/common/Form/Input';
import CloseIcon from 'components/common/svg/close';

import AttachFile from 'components/common/AttachFile';

import { ProfileService } from 'api/profile';
import useCurrentUser from 'store/currentUser';
import { userStorage } from 'store/storage';

import { verificationResolver } from 'config/resolvers';
import useTranslates from 'utils/translate';
import useToaster from 'hooks/useToaster';


import sx from './styles';
import { VerificationStatus } from 'types/auth';

interface VerificationDialogProps {
  open: boolean;
  onClose: () => void;
}

const VerificationDialog: FC<VerificationDialogProps> = ({ open, onClose }) => {
  const notify = useToaster();
  const { translate } = useTranslates();
  const [, currentUserActions] = useCurrentUser();

  const { control, reset, watch, setValue, handleSubmit } = useForm({
    resolver: verificationResolver,
  });

  const fileValue = watch('url');

  useEffect(() => {
    reset({});
  }, [open]);

  const onUpdatePassword = (data) => {
    ProfileService.setDocuments({
      ...data,
    }).then((res) => {
      if (res?.id) {
        onClose();
        notify({
          message: translate('document_successfully_uploaded'),
        });
        currentUserActions.set({
          ...userStorage.get(),
          verificationStatus: VerificationStatus.AWAITING_VERIFICATION,
        });
      }
    });
  };

  const handleChangeFile = (fileUrl) => {
    setValue('url', fileUrl);
  };

  return (
    <Dialog keepMounted open={open} onClose={() => onClose()}>
      <DialogTitle>
        <Typography fontSize={18} fontWeight={600}>
          {translate('verification')}
        </Typography>
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack gap={3} mb={4} key={open ? 1 : 2}>
          <Typography textAlign="center">{translate('verification_description')}</Typography>
          <Box textAlign="center">
            {fileValue ? (
              <AspectRatioWrapper>
                <AspectRatio divide="8:5">
                  <Box sx={sx.image({ src: fileValue })}>
                    <IconButton sx={sx.removeFileIconButton} onClick={() => handleChangeFile('')}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </AspectRatio>
              </AspectRatioWrapper>
            ) : (
              <AttachFile onChange={handleChangeFile}>
                <Button color="secondary" variant="contained">
                  {translate('upload_passport')}
                </Button>
              </AttachFile>
            )}
          </Box>
          <FormInput control={control} name="serialId" placeholder={translate('passport_series')} />
        </Stack>
        <Button fullWidth variant="contained" onClick={handleSubmit(onUpdatePassword)} disabled={!fileValue}>
          {translate('verify_now_button')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default VerificationDialog;
