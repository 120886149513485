import { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Collapse, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';

import Logo from 'components/common/svg/logo';

import facebookPng from 'assets/Facebook.png';
import telegramPng from 'assets/Telegram.png';
import twitterPng from 'assets/Twitter.png';
import whatsappPng from 'assets/Whatsapp.png';
import youtubePng from 'assets/Youtube.png';
import instagramPng from 'assets/instagram.png';

import licenseImage from 'assets/license.png';

import useCategories from 'store/categories';
import useSettings, { IWebsiteConfigs } from 'store/settings';
import useSelectedTheme from 'store/theme';

import useTranslates from 'utils/translate';
import useScreen from 'hooks/useScreen';

import ImageSlider from './ImagesSlider';

import useWebsiteContent from 'store/websiteContent';
import Chat from '../Chat';
import { DESKTOP_FOOTER_ID } from '../Navigation/constants';
import sx from './styles';
import useWebsiteSports from 'store/websiteSports';

const SocialIconButton = ({ settings, settingsKey, image }) => {
  const value = settings[settingsKey];

  const openNewTab = () => {
    window.open(value, '_blank');
  };

  return (
    value && (
      <Grid item xs={4}>
        <IconButton sx={sx.socIcon} onClick={openNewTab}>
          <img src={image} />
        </IconButton>
      </Grid>
    )
  );
};

const FooterLink = ({ route, text }) => {
  const { translate } = useTranslates();

  return (
    <Link to={route}>
      <Typography sx={sx.text}>{translate(text)}</Typography>
    </Link>
  );
};

const Footer = () => {
  const { isMobile } = useScreen();
  const { pathname } = useLocation();
  const { translate } = useTranslates();
  const [{ isDark }] = useSelectedTheme();
  const [websiteContents] = useWebsiteContent();
  const [websiteSports] = useWebsiteSports();

  const [categories] = useCategories();
  const [
    {
      data: {
        providerLogosDark,
        providerLogos,
        paymentLogos,
        paymentLogosDark,
        sponsorshipLogos,
        sponsorshipLogosDark,
        meta = {} as IWebsiteConfigs,
        ...settings
      },
    },
  ] = useSettings();

  const lockingFooter = pathname === '/' && meta.lockMobileFooter && isMobile;

  const [expanded, setExpanded] = useState(lockingFooter || false);

  const wrappedSetExpanded = useCallback(
    (value: SetStateAction<boolean>) => {
      if (!lockingFooter) {
        setExpanded(value);
      }
    },
    [lockingFooter]
  );

  const toggleExpanded = () => wrappedSetExpanded((p) => !p);

  useEffect(() => {
    wrappedSetExpanded(false);
  }, [pathname]);

  const handleClickArrow = () => {
    toggleExpanded();
  };

  const providerLogosList = useMemo(
    () =>
      isDark
        ? providerLogosDark?.length
          ? providerLogosDark
          : providerLogos
        : providerLogos?.length
        ? providerLogos
        : providerLogosDark,
    [providerLogosDark, providerLogos, isDark]
  );

  const sponsorLogosList = useMemo(
    () =>
      isDark
        ? sponsorshipLogosDark?.length
          ? sponsorshipLogosDark
          : sponsorshipLogos
        : sponsorshipLogos?.length
        ? sponsorshipLogos
        : sponsorshipLogosDark,
    [sponsorshipLogosDark, sponsorshipLogos, isDark]
  );

  const paymentLogosList = useMemo(
    () =>
      isDark
        ? paymentLogosDark?.length
          ? paymentLogosDark
          : paymentLogos
        : paymentLogos?.length
        ? paymentLogos
        : paymentLogosDark,
    [paymentLogosDark, paymentLogos, isDark]
  );

  if (pathname.includes('sport') && isMobile) return null;

  return (
    <Box sx={sx.footer({ isMobile })} component="footer" id={DESKTOP_FOOTER_ID}>
      {!lockingFooter && (
        <IconButton sx={sx.expandableIcon({ expanded })} onClick={handleClickArrow}>
          <ArrowDropDownIcon />
        </IconButton>
      )}
      <Collapse in={lockingFooter || expanded} timeout="auto" unmountOnExit>
        <Box className="content" py={4}>
          <Grid container spacing={2}>
            <Grid item md={2} xs={12}>
              <Box textAlign={isMobile ? 'center' : 'left'}>
                <Link to="/">
                  <Logo />
                </Link>
              </Box>
              <Stack mt={1} gap={1} alignItems={isMobile && 'center'}>
                <Typography sx={sx.text}>{settings.address}</Typography>
                <Typography sx={sx.text}>{settings.phoneNumber}</Typography>
                <Typography sx={sx.text}>{settings.email}</Typography>
                {(meta.footerContactInformation || []).map((item) => (
                  <Typography key={item} sx={sx.text}>
                    {item}
                  </Typography>
                ))}
                <Box ml="-8px !important" width="140px">
                  <Grid container>
                    <SocialIconButton settings={settings} settingsKey="instagram" image={instagramPng} />
                    <SocialIconButton settings={settings} settingsKey="facebook" image={facebookPng} />
                    <SocialIconButton settings={settings} settingsKey="youtube" image={youtubePng} />
                    <SocialIconButton settings={settings} settingsKey="telegram" image={telegramPng} />
                    <SocialIconButton settings={settings} settingsKey="twitter" image={twitterPng} />
                    <SocialIconButton settings={settings} settingsKey="viber" image={whatsappPng} />
                  </Grid>
                </Box>
              </Stack>
            </Grid>
            {!isMobile && (
              <>
                <Grid item md={2} xs={12}>
                  <Typography sx={sx.subtitle}>Categories</Typography>
                  <Stack mt={1} gap={0.5}>
                    <FooterLink route="/" text="home" />
                    <FooterLink route={`/sport/${websiteSports.data[0]?.id}`} text="sport" />
                    <FooterLink route="/games" text="casino" />
                  </Stack>
                </Grid>
                <Grid item md={2} xs={12}>
                  <Typography sx={sx.subtitle}>{translate('games')}</Typography>
                  <Stack mt={1} gap={0.5} sx={{ '& a': { width: 'fit-content' } }}>
                    {categories?.data?.map((item) => (
                      <FooterLink key={item.key} route={`/games/${item.uri}`} text={item.name} />
                    ))}
                  </Stack>
                </Grid>
                <Grid item md={2} xs={12}>
                  <Typography sx={sx.subtitle}>{translate('legal')}</Typography>
                  <Stack mt={1} gap={0.5}>
                    {websiteContents.data.map((content) => (
                      <FooterLink
                        key={content.url}
                        route={`/info/${content.url}`}
                        text={content.translations[0].title}
                      />
                    ))}
                  </Stack>
                </Grid>
                <Grid item md={2} xs={12}>
                  <Typography sx={sx.subtitle}>{translate('other')}</Typography>
                  <Stack mt={1} gap={0.5}>
                    {!!meta.affiliateUrl && <FooterLink route={meta.affiliateUrl} text={translate('affiliate')} />}
                    <FooterLink route="/promotions" text={translate('promotions')} />
                    {!!meta.androidAppUrl && (
                      <a target="_self" style={sx.text} href={meta.androidAppUrl} download={window.location.host}>
                        {translate('android_application')}
                      </a>
                    )}
                  </Stack>
                </Grid>
              </>
            )}
            {meta.showLicense && <Grid item md={2} xs={12}>
              <Stack gap={1}>
                <img width={54} src={licenseImage} alt="License" />
                <Typography sx={sx.text}>{translate('footer_license_text')}</Typography>
              </Stack>
            </Grid>}
          </Grid>
          {!!sponsorLogosList?.length && (
            <>
              <Typography display="flex" justifyContent="center" paddingBottom="8px">{translate('sponsorships')}</Typography>
              <Box width="100%" px={3}>
                <ImageSlider images={sponsorLogosList} />
              </Box>
            </>
          )}
          {!!providerLogosList?.length && (
            <>
              <Typography display="flex" justifyContent="center" paddingBottom="8px">{translate('providers')}</Typography>
              <Box width="100%" px={3}>
                <ImageSlider images={providerLogosList} />
              </Box>
            </>
          )}
        </Box>
        <Divider sx={{ bgcolor: 'background.paper' }} />
        <Box
          py={1}
          width="100%"
          display="flex"
          className="content"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          {!!paymentLogosList?.length && <>
            <Typography display="flex" justifyContent="center" paddingBottom="8px">{translate('payment_providers')}</Typography>
            <ImageSlider images={paymentLogosList} />
          </>}
          {meta.showLicense && <Typography sx={sx.copyright}>
            {translate('footer_license_text')}
          </Typography>}
          <Typography sx={sx.copyright}>
            {new Date().getFullYear()}. {translate('all_right_reserved')}
          </Typography>
        </Box>
      </Collapse>
      {/* {!isMobile && <Chat />} */}
      <Chat />
    </Box>
  );
};

export default Footer;
