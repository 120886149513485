import { useEffect, useMemo, useRef, useState } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Divider, Menu, MenuItem, Stack } from '@mui/material';

import BonusIcon from 'components/common/icons/Bonus';
import Logo from 'components/common/svg/logo';

import Actions from 'components/sections/Navigation/Actions';
import UserSection from 'components/sections/Navigation/UserSection';

import useCategories from 'store/categories';

import useTranslates from 'utils/translate';

import useSettings, { IWebsiteConfigs } from 'store/settings';
import useWebsiteSports from 'store/websiteSports';
import { DESKTOP_HEADER_ID, DESKTOP_HEADER_PADDING_LEFT, DESKTOP_HEADER_PADDING_RIGHT } from '../constants';
import sx from './styles';
import { hasPrematchType } from 'utils/predicates';
import useSelectedTheme from 'store/theme';

const PROMOTION_BUTTON_WIDTH = 180;
const MENU_ITEM_GAP = 16;
const PROMOTION_BUTTON_PADDING = 40;
const MORE_ITEM_SAFE_ZONE = 100 + MENU_ITEM_GAP;

const MenuSection = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { translate } = useTranslates();

  const [settings] = useSettings();
  const [categories] = useCategories();
  const [websiteSports] = useWebsiteSports();
  const [
    {
      data: { 
        meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();
  const navigationMenuRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCategoriesCount, setShowCategoriesCount] = useState(0);

  const prematchSports = useMemo(() => websiteSports.data.filter(hasPrematchType), [
    websiteSports.data,
  ]);

  const staticButtonsCount = useMemo(
    () => (prematchSports?.length || 0) + (meta.showGamesInTopNavigation ? 1 : 0) + 1, // last + 1 its "home" button
    [prematchSports]
  );

  const isHomepageActive = settings?.data?.meta?.isHomepageActive;

  const handleHover = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resize = () => {
    if (navigationMenuRef.current) {
      const header = document.getElementById(DESKTOP_HEADER_ID);
      const headerWidth =
        header.getBoundingClientRect().width - DESKTOP_HEADER_PADDING_RIGHT - DESKTOP_HEADER_PADDING_LEFT;
      const contentWidth =
        headerWidth - (PROMOTION_BUTTON_WIDTH + PROMOTION_BUTTON_PADDING) - MENU_ITEM_GAP * 2 - MORE_ITEM_SAFE_ZONE;
      const items = Array.from(navigationMenuRef.current.children as HTMLElement[]).map(
        (item: HTMLElement) => item.getBoundingClientRect().width + MENU_ITEM_GAP
      );

      const selectedItems = items.reduce(
        (accumulator, currentItem) => {
          if (accumulator.sum + currentItem <= contentWidth) {
            accumulator.sum += currentItem;
            accumulator.selected.push(currentItem);
          }
          return accumulator;
        },
        { sum: 0, selected: [] }
      );

      setShowCategoriesCount(selectedItems.selected.length - staticButtonsCount);
    }
  };

  const resizeRef = useRef(resize);
  resizeRef.current = resize;

  useEffect(() => {
    resizeRef.current();
  }, [prematchSports.length]);

  useEffect(() => {
    const mutationObserver = new MutationObserver(() => resizeRef.current());
    mutationObserver.observe(navigationMenuRef.current, { childList: true, subtree: true });

    resizeRef.current();

    window.addEventListener('resize', () => resizeRef.current());
    return () => {
      window.removeEventListener('resize', resizeRef.current);
      mutationObserver.disconnect();
    };
  }, [navigationMenuRef, staticButtonsCount]);

  const moreItems =
    showCategoriesCount <= categories?.data?.length
      ? categories?.data?.filter((_, index) => index > showCategoriesCount)
      : [];

  const hideFromSportItemsCount = showCategoriesCount < 0 ? Math.abs(showCategoriesCount) : 0;


  return (
    <Box sx={sx.menu}>
      <Box flex={1} component="nav" role="navigation">
        <Stack ref={navigationMenuRef} gap={`${MENU_ITEM_GAP}px`} direction="row" component="ul">
          {isHomepageActive && (
            <Box component="li">
              <Link to="/">
                <Button sx={sx.button({ selected: pathname === '/' })}>{translate('home')}</Button>
              </Link>
            </Box>
          )}
          {prematchSports
            .filter((_, index) => prematchSports.length - 1 - hideFromSportItemsCount >= index)
            .map((item, index) => {
              const path = !isHomepageActive && !index ? '/' : `/sport/${item.id}`;

              return (
                <Box component="li" key={item.id}>
                  <Link to={path}>
                    <Button sx={sx.button({ selected: pathname === path })}>{translate(`sport_${item.id}`)}</Button>
                  </Link>
                </Box>
              );
            })}
          {meta.showGamesInTopNavigation && (
            <Box component="li">
              <Button onClick={() => navigate('/games')} sx={sx.button({ selected: pathname === '/games' })}>
                {translate('games')}
              </Button>
            </Box>
          )}

          {categories?.data?.map((item, index) => {
            const path = !Number.isNaN(Number(item.uri)) ? `/game/${item.uri}` : `/games/${item.uri}`;

            return (
              <Box
                key={item.key}
                component="li"
                sx={{
                  right: showCategoriesCount < index && '-100vw',
                  position: showCategoriesCount < index ? 'absolute' : 'relative',
                }}
              >
                <Link to={path}>
                  <Button sx={sx.button({ selected: pathname === path })}>{item.name}</Button>
                </Link>
              </Box>
            );
          })}
          <Box component="li">
            <Button
              sx={{
                ...sx.moreButton,
                right: !moreItems.length && '-100vw',
                position: !moreItems.length ? 'absolute' : 'relative',
              }}
              onMouseOver={handleHover}
              endIcon={<ExpandMoreIcon fontSize="small" />}
            >
              +{moreItems.length + hideFromSportItemsCount} {translate('more')}
            </Button>
          </Box>
          <Menu
            anchorEl={anchorEl}
            onClose={handleClose}
            open={Boolean(moreItems.length && anchorEl)}
            MenuListProps={{ onMouseLeave: handleClose }}
          >
            {moreItems.map((item) => (
              <Link key={item.id} to={`/games/${item.uri}`}>
                <MenuItem
                  onClick={() => {
                    handleClose();
                  }}
                  selected={pathname === `/games/${item.uri}`}
                  sx={{ fontSize: 14 }}
                >
                  {item.name}
                </MenuItem>
              </Link>
            ))}
          </Menu>
        </Stack>
      </Box>
      <Link to="/promotions">
        <Button
          sx={{
            width: `${PROMOTION_BUTTON_WIDTH}px`,
            paddingLeft: `${PROMOTION_BUTTON_PADDING}px`,
            paddingRight: `${PROMOTION_BUTTON_PADDING}px`,
            zIndex: 100,
          }}
          variant="contained"
          startIcon={<BonusIcon fill="white" />}
        >
          {translate('promotions')}
        </Button>
      </Link>
    </Box>
  );
};

const DesktopNavigation = () => {
  const [{ isDark }] = useSelectedTheme();
  const [
    {
      data: { 
        sponsorshipLogos,
        sponsorshipLogosDark,
      },
    }
  ] = useSettings();
  const sponsorLogosList = useMemo(
    () =>
      isDark
        ? sponsorshipLogosDark?.length
          ? sponsorshipLogosDark
          : sponsorshipLogos
        : sponsorshipLogos?.length
        ? sponsorshipLogos
        : sponsorshipLogosDark,
    [sponsorshipLogosDark, sponsorshipLogos, isDark]
  );

  return (
    <Box
      sx={sx.root}
      style={{
        paddingLeft: DESKTOP_HEADER_PADDING_LEFT,
        paddingRight: DESKTOP_HEADER_PADDING_RIGHT,
      }}
      id={DESKTOP_HEADER_ID}
    >
      <Box sx={sx.actions}>
        <Box display="flex">
          <Link to="/">
            <Logo useInlineBounds />
          </Link>
          {!!sponsorLogosList?.length && (
            <>
              <Divider
                orientation="vertical"
                style={{
                  width: '1px',
                  marginRight: '8px',
                  marginLeft: '8px',
                  height: '35px',
                  marginTop: '6px',
                  backgroundColor: 'grey',
                }}
              />
              <Box display="flex" gap="4px" alignItems="center">
                {sponsorLogosList.map((sponsorLogo) => (
                  <img style={{ display: 'flex' }} height="36px" key={sponsorLogo} src={sponsorLogo} alt="sponsor" />
                ))}
              </Box>
            </>
          )}
        </Box>
        <Stack direction="row" alignItems="center" gap={2}>
          <Actions />
          <UserSection />
        </Stack>
      </Box>
      <MenuSection />
    </Box>
  );
};

export default DesktopNavigation;
