export const convertTime = (value: number) => {
  if (value < 60) return `${value} s`;

  if (value < 3600) {
    const minutes = Math.floor(value / 60);
    return  `${minutes} m`;
  }
  if (value < 86400) {
    const hours = Math.floor(value / 3600);
    return `${hours} h`;
  }

  const days = Math.floor(value / 86400);
  return `${days} d`;
};

const pow10_3 = Math.pow(10, 3);
const pow10_6 = Math.pow(10, 6);
const pow10_9 = Math.pow(10, 9);
const pow10_12 = Math.pow(10, 12);


const INFINITY_SIGN_UNICODE = '\u221E';

export const convertAmount = (value?: number) => {
  if (!value) return '0';

  if (value < pow10_3) return value.toString();

  if (value < pow10_6) return (value / pow10_3).toFixed(1) + 'K';

  if (value < pow10_9) return (value / pow10_6).toFixed(1) + 'M';

  if (value < pow10_12) return (value / pow10_9).toFixed(1) + 'T';

  if (value === Number.MAX_SAFE_INTEGER) return INFINITY_SIGN_UNICODE;

  return (value / pow10_12).toFixed(1) + 'Q';
};
