import { Button, Grid, Typography } from '@mui/material';
import { PaymentMethodsService } from 'api/paymentMethods';
import AttachFile from 'components/common/AttachFile';
import FormInput from 'components/common/Form/Input';
import { useCallback, useEffect, useRef, useState } from 'react';
import useTranslates from 'utils/translate';
import useDebounce from 'hooks/useDebounce';
import useMount from 'hooks/useMount';
import { PAYMENT_INFO_DELAY } from './constants';
import { LoadingButton } from '@mui/lab';
import useToaster from 'hooks/useToaster';

const AutomaticDeposit = ({
  watch,
  control,
  onClose,
  setValue,
  getValues,
  getInfoProperties,
  paymentMethod,
  changeAmountFieldVisibility,
  changeActionButtonVisibility,
}) => {
  const amount = watch('amount');
  const confirmationCode = watch('confirmationCode');
  const confirmationImage = watch('confirmationImage');
  const ticketId = watch('ticketId');

  const notify = useToaster();
  const [walletInfo, setWalletInfo] = useState('');
  const [infoName, setInfoName] = useState('');
  const [infoImage, setInfoImage] = useState('');
  const [loading, setLoading] = useState(false);
  const { translate } = useTranslates();
  const handler = useCallback(
    async (amount: number, sourceId: number) => {
      const result = await PaymentMethodsService.getPaymentMethodExtraInfo<[{ key: string; value: string }]>({
        amount,
        infoProperties: getInfoProperties(),
        sourceId,
        type: 'deposit',
      });
      setWalletInfo(result.find(({ key }) => key === 'account').value);
      setInfoImage(result.find(({ key }) => key === 'image').value);
      setInfoName(result.find(({ key }) => key === 'name').value);
      setValue('ticketId', result.find(({ key }) => key === 'ticket').value);
    },
    [getInfoProperties]
  );

  const debouncedPaymentMethodExtraInfo = useDebounce(handler, PAYMENT_INFO_DELAY);

  useMount(() => {
    changeActionButtonVisibility(false);
    changeAmountFieldVisibility(true);
  });

  useEffect(() => {
    if (amount) {
      debouncedPaymentMethodExtraInfo(amount, paymentMethod.method.id);
    }
  }, [amount, paymentMethod.method.id, debouncedPaymentMethodExtraInfo]);

  const handleChangeFile = (fileUrl) => {
    setValue('confirmationImage', fileUrl);
  };

  const amountRef = useRef(amount);
  useEffect(() => {
    if (amountRef.current !== amount) {
      setValue('confirmationCode', '');
      setValue('confirmationImage', '');
      setValue('ticketId', '');
      setWalletInfo('');
    }
    amountRef.current = amount;
  }, [amount]);

  const isDepositButtonEnabled= !!confirmationCode && !!confirmationImage && !!ticketId && !!walletInfo && !!amount;

  const deposit = useCallback(async () => {
    setLoading(true);
    const data = getValues();

    const successMessage = 'Deposit request has been sent successfully';
    const errorMessage = 'Deposit request has been failed';

    try {
      const response = await PaymentMethodsService.deposit({
        ...data,
        sourceId: paymentMethod.method.id,
        infoProperties: getInfoProperties(),
      });
      notify({
        ...(!response && { type: 'error' }),
        message: response ? successMessage : errorMessage,
      });
      response && onClose();
    } catch {
      notify({
        type: 'error',
        message: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <>
      {!!walletInfo && (
        <Grid item xs={12} display="flex" gap={2}>
          <Typography>{translate('wallet_info')}</Typography>
          <Typography>{walletInfo}</Typography>
        </Grid>
      )}
      {!!infoName && (
        <Grid item xs={12} display="flex" gap={2}>
          <Typography>{translate('automatic_deposit_name')}</Typography>
          <Typography>{infoName}</Typography>
        </Grid>
      )}
      {!!infoImage && (
        <Grid item xs={12} display="flex" gap={2}>
          <Typography>{translate('automatic_deposit_image')}</Typography>
          <img src={infoImage} width={100} />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormInput
          label={translate('confirmation_code')}
          disabled={!walletInfo}
          name="confirmationCode"
          required
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <AttachFile onChange={handleChangeFile}>
          <Button color="secondary" disabled={!walletInfo} variant="contained">
            {translate('confirmation_image')}
          </Button>
        </AttachFile>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <LoadingButton
          loading={loading}
          disabled={!isDepositButtonEnabled}
          variant="contained"
          color="success"
          onClick={deposit}
          sx={{ mt: 3 }}
          type="submit"
        >
          {translate('deposit_now')}
        </LoadingButton>
      </Grid>
    </>
  );
};

export default AutomaticDeposit;
