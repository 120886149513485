import { FC } from 'react';
import { Control, FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { PaymentMethod } from 'types';
import InHouse from './InHouse';
import BorderLess from './Borderless';
import Passim from './PassimPay';

export const PAYMENT_AGGREGATORS = {
  IN_HOUSE: 3,
  BORDER_LESS: 4,
  PASSIM_PAY: 5,
};

const PaymentService: FC<{
  isDeposit: boolean;
  onClose: () => void;
  getValues: () => FieldValues;
  paymentMethod: PaymentMethod;
  control: Control<FieldValues, unknown>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  changeAmountFieldVisibility: (isVisible: boolean) => void;
  changeActionButtonVisibility: (isVisible: boolean) => void;
  changeActionButtonAvailability: (isAvailable: boolean) => void;
}> = (props) => {
  const aggregatorId = props.paymentMethod?.method?.provider.aggregator.id;
  if (PAYMENT_AGGREGATORS.IN_HOUSE === aggregatorId) {
    return <InHouse {...props} />;
  }

  if (PAYMENT_AGGREGATORS.BORDER_LESS === aggregatorId) {
    return <BorderLess {...props} />;
  }

  if (PAYMENT_AGGREGATORS.PASSIM_PAY === aggregatorId) {
    return <Passim {...props} />;
  }

  console.log('payment service not found');
  return null;
};

export default PaymentService;
